import '../styles/main.scss';

const jQuery = require("jquery");
window.$ = window.jQuery = jQuery;

/*
 * Import Vue in project
 */
import Vue from 'vue/dist/vue.js';
Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.config.debug = false;

/*
 * Use masonry system
 */
import VueMasonry from 'vue-masonry-css';
Vue.use(VueMasonry);

//vueExporter to include vue-Functions only for elements on the current page
function vueImporter(selector, options) {
  if ($(selector).length > 0) {
    options.el = selector;
    new Vue(options);
  }
}

/*
 * handles the header scroll fade animation
 */

vueImporter("#vm_1", {
  data () {
    return {
      menuStatus: '',
      scrolled: false
    };
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      this.scrolled = window.scrollY > 0;
    }
  }
});


// LIGHTBOX version 0.0.1

Vue.component('light-box', {
  props: ['animation','images', 'imagep','captionp', 'titlep', 'image', 'index', 'caption','title', 'imagea', 'captiona','titlea', 'right', 'left','in','out','thumbs','close','buttons'],
  template: `
    <div class="partial-lightbox_wrapper" @keyup="keyEventStart">
      <span v-if="close" class="partial-lightbox_button partial-lightbox_button-close" @click="closeLightBox">
        <i class="fas fa-times"></i>
      </span>
      <div class="partial-lightbox_stage-left" @click="previousImage">
        <span v-if="buttons" class="partial-lightbox_button partial-lightbox_button-prev" :class="{disabled: left}">
          <i class="fas fa-chevron-left"></i>
        </span>
      </div>
      <div class="partial-lightbox_stage-middle ">
        <div class="partial-lightbox_preview">

            <figure v-if="imagep" class="partial-lightbox_figure activePrev">
              <img :src="imagep" :alt='titlep' class="partial-lightbox_figure-image">
              <figcaption v-if="titlep" class="partial-lightbox_figure-caption">{{titlep}}<br>{{captionp}}</figcaption>
            </figure>

            <figure class="partial-lightbox_figure active">
              <img :src="image" :alt='title' class="partial-lightbox_figure-image">
              <figcaption v-if="title" class="partial-lightbox_figure-caption">{{title}}<br>{{caption}}</figcaption>
            </figure>

            <figure v-if="imagea" class="partial-lightbox_figure activeNext">
              <img :src="imagea" :alt='titlea' class="partial-lightbox_figure-image">
              <figcaption v-if="titlea" class="partial-lightbox_figure-caption">{{titlea}}<br>{{captiona}}</figcaption>
            </figure>
        </div>
        <div v-if="thumbs" class="partial-lightbox_thumbs">
          <img @click="changeItem" class="partial-lightbox_thumbs-icon" 
          v-for="image in images" :src="image.src" :index="image.position">
        </div>
      </div>
      <div class="partial-lightbox_stage-right" @click="nextImage">
        <span v-if="buttons" class="partial-lightbox_button partial-lightbox_button-next" :class="{disabled: right}">
          <i class="fas fa-chevron-right"></i>
        </span>
      </div>
    </div>`,
  beforeMount () {
    window.addEventListener('keyup', this.keyBoardControl);
  },

  beforeDestroy () {
    window.removeEventListener('keyup', this.keyBoardControl);
  },

  methods: {
    changeItem(e) {
      this.$parent.changeItem(e);
    },
    closeLightBox() {
      this.$parent.closeLightBox();
    },
    previousImage() {
      this.$parent.previousImage();
    },
    nextImage() {
      this.$parent.nextImage();
    },
    keyEventStart() {
      this.$parent.keyEventStart();
    },
    keyBoardControl(e) {
      // escape key event
      if(e.keyCode == '27'){
        this.closeLightBox();
      }

      // left-arrow key event
      if(e.keyCode == '37'){
        this.previousImage();
      }

      // right-arrow key event
      if(e.keyCode == '39'){
        this.nextImage();
      }
    }
  }
});

vueImporter("#vm_2", {
  data: {
    classItem: 'lightbox_item',
    r: 0,
    itemsList: [],
    isOpen: false,
    isThumb: false,
    isButtons: true,
    isCloseButton: true,
    prevItemTitle: '',
    prevItemHref: '',
    prevItemCaption: '',
    prevItemPosition: '',
    currentItemHref: '',
    currentItemCaption: '',
    currentItemPosition: '',
    currentItemTitle: '',
    nextItemHref: '',
    nextItemCaption: '',
    nextItemPosition: '',
    nextItemTitle: '',
    isRight: false,
    isLeft: false,
    eventInClass: false,
    eventOutClass: false,
    switchImage: false
  },
  methods: {
    createLightBox: function (e) {
      //create lightbox 
      e.preventDefault();

      //get value for the current item
      this.currentItemHref = e.target.parentNode.href;
      this.currentItemTitle = e.target.alt;
      this.currentItemPosition = e.target.dataset['lightbox_position'];
      this.currentItemCaption = e.target.dataset['lightbox_caption'];

       //animation event
      this.switchImage = !this.switchImage;

      // push every item with class in the array
      let items = this.$el.querySelectorAll("." + this.classItem);
      for (this.r; this.r < items.length; this.r++) {
        let nummer = items[this.r];
        // this.itemsList.push({ src: nummer.href, alt: nummer.querySelector('img').alt, position: this.r, description: nummer.querySelector('img').dataset['lightbox_caption'] })
        this.itemsList.push({ src: nummer.href, alt: nummer.querySelector('img').alt, position: nummer.querySelector('img').dataset['lightbox_position'], description: nummer.querySelector('img').dataset['lightbox_caption'] })
      }

      this.itemsList.sort(function(x, y) {
        return x.position - y.position;
      });
      this.itemsList = this.itemsList;

      /* 
       * - code for a normal mode without the masonry grid
       * this.itemsList.push({ src: nummer.href, alt: nummer.querySelector('img').alt, position: nummer.querySelector('img').dataset['lightbox_position'], description: nummer.querySelector('img').dataset['lightbox_caption'] })
       */

      //get value for the previous item
      let indexPrev =  parseInt(this.currentItemPosition) - 1;
      let prevItem = this.itemsList[indexPrev];
      this.prevImageValue(prevItem);

      //get value for the next item
      let indexNext =  parseInt(this.currentItemPosition) + 1;
      let nextItem = this.itemsList[indexNext];
      this.nextImageValue(nextItem);

      //show lightbox on screen
      this.isOpen = true;

      //function for arrows
      this.controlArrows(this.currentItemPosition-1, this.itemsList.length);

    },


    previousImage: function() {
      //slide to the previous image
      let position = parseInt(this.currentItemPosition),
        index = position - 2;
      this.prevItemPosition = index - 1;
      this.nextItemPosition = parseInt(index);

      //function for arrows
      this.controlArrows(index, this.itemsList.length);

      //if condition for the previous arrow
      if (this.currentItemPosition <= 1) {
        return
      }

      //change the value in the lightbox for all three items
      this.setValue(index, this.prevItemPosition, this.nextItemPosition);

      //animation event
      this.switchImage = !this.switchImage;
    },


    nextImage: function() {
      //slide to the next image
      let position = parseInt(this.currentItemPosition),
        index = position;
      this.prevItemPosition = position - 1;
      this.nextItemPosition = parseInt(index) + 1;
      this.controlArrows(index, this.itemsList.length);
      //if condition for the next arrow
      if (this.currentItemPosition >= this.itemsList.length) {
        return
      };

      //change the value in the lightbox for all three items
      this.setValue(index, this.prevItemPosition, this.nextItemPosition);

      //animation event
      this.switchImage = !this.switchImage;
    },


    changeItem: function(e) {
      let position = e.target.attributes.index.value,
        index = position;
      this.prevItemPosition = parseInt(index) - 1;
      this.nextItemPosition = parseInt(index) + 1;

      //function for arrows
      this.controlArrows(index, this.itemsList.length)

      //change the value in the lightbox for all three items
      this.setValue(index, this.prevItemPosition, this.nextItemPosition);
    },


    setValue: function(index, indexPrev, indexNext) {

      let prevItem = this.itemsList[indexPrev];
      this.prevImageValue(prevItem);
      
      let activeItem = this.itemsList[index];
      this.currentImageValue(activeItem);

      let nextItem = this.itemsList[indexNext];
      this.nextImageValue(nextItem);
    },


    /*  Function for getting all values for the current item in array */
    currentImageValue: function(activeItem){
      this.currentItemHref = activeItem.src;
      this.currentItemPosition = activeItem.position;
      this.currentItemTitle = activeItem.alt;
      this.currentItemCaption = activeItem.description;

    },



    /*  Function for getting all values for the previous item in array */
    prevImageValue: function(prevItem){
      if(prevItem == null){
        this.prevItemHref = '';
        this.prevItemPosition = '';
        this.prevItemTitle = '';
        this.prevItemCaption = '';
      }else {
        this.prevItemHref = prevItem.src;
        this.prevItemPosition = prevItem.position;
        this.prevItemTitle = prevItem.alt;
        this.currentItemCaption = prevItem.description;
      };
    },


    /*  Function for getting all values for the next item in array */
    nextImageValue: function(nextItem){
      if(nextItem == null){
        this.nextItemHref = '';
        this.nextItemPosition = '';
        this.nextItemTitle = '';
        this.nextItemCaption = '';
      }else{
        this.nextItemHref = nextItem.src;
        this.nextItemPosition = nextItem.position;
        this.nextItemTitle = nextItem.alt;
        this.nextItemCaption = nextItem.description;
      }
    },


    /*  Function for Arrows:
      //   - index is current number of the image
      //   - amount lenght for array which contain all images
    */
    controlArrows: function(index, amount) {
      if (index == '0') {
        this.isLeft = true;
      };
      if (index >= 1 && index < amount - 1) {
        this.isLeft = false;
        this.isRight = false;
      };
      if (index == amount - 1) {
        this.isRight = true;
      };
    },

    /*  Function for closing LightBox:
      //  reset all classes
    */
    closeLightBox: function () {
      this.isOpen = false;
      this.isRight = false;
      this.isLeft = false;
    }
  },
});




